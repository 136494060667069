import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const ProfessionalServiceFirms = () => {
  const title = "Commercial Banking for Professional Services";
  const description =
    "Are you a professional service firm in need of commercial banking services? WaFd Bank can help with Line of credit and shareholder loan payments";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/professional-service-firms/hero-professional-service-firms-01042023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/specialized-industries/professional-service-firms"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-professional-service-firms-01042023-250.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "professional-service-firms-hero",
    ...getHeroImgVariables(imgData),
    altText: "Two business people walking and talking in an office building",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Find Success with WaFd Bank",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            type: "button",
            text: "Contact Us",
            class: "btn-white",
            onClick: () => showMktoForm(1067)
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/specialized-industries",
      title: "Specialized Industries"
    },
    {
      id: 3,
      active: true,
      title: "Professional Service Firms"
    }
  ];
  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Professional Service Firms</h1>

        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnail-professional-service-firms-031124.jpg"
              alt="Two businesswomen reviewing content on a tablet."
              quality="100"
              placeholder="blurred"
              className="border-radius-12"
            />
          </div>
          <div className="col-md-6">
            <h4>
              Our services can help your firm if you need financing to support seasonal cash flows, upgrade equipment,
              or support growth. Additionally, customized shareholder buy-in facilities can be set up to assist with
              ownership additions or changes.
            </h4>
            <ul id="professional-service-firms-list">
              <li id="professional-service-firms-list-1">
                Lines of credit can help with seasonal cash flow needs and support growth&mdash;Term loans can finance
                equipment and real estate purchases, as well as the acquisition of additional businesses
              </li>
              <li id="professional-service-firms-list-2">
                Shareholder loan payments can be structured as interest only with principal payments matching the firm's
                bonus cycles
              </li>
            </ul>
            <a
              id="contact-commercial-banker-form-cta"
              className="btn btn-primary w-100 w-sm-auto no-min-width"
              href="/#"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                showMktoForm(1067);
              }}
            >
              Contact Us
            </a>
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default ProfessionalServiceFirms;
